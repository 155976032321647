import getClientRect from '../utils/getClientRect';

/**
 * Modifier used to make sure the popper is always near its reference element
 * It cares only about the first axis, you can still have poppers with margin
 * between the popper and its reference element.
 * @method
 * @memberof Modifiers
 * @argument {Object} data - The data object generated by update method
 * @argument {Object} options - Modifiers configuration and options
 * @returns {Object} The data object, properly modified
 */
export default function keepTogether(data) {
    const popper  = getClientRect(data.offsets.popper);
    const reference = data.offsets.reference;
    const placement = data.placement.split('-')[0];
    const floor = Math.floor;

    if (['top', 'bottom'].indexOf(placement) !== -1) {
        if (popper.right < floor(reference.left)) {
            data.offsets.popper.left = floor(reference.left) - popper.width;
        }
        if (popper.left > floor(reference.right)) {
            data.offsets.popper.left = floor(reference.right);
        }
    } else {
        if (popper.bottom < floor(reference.top)) {
            data.offsets.popper.top = floor(reference.top) - popper.height;
        }
        if (popper.top > floor(reference.bottom)) {
            data.offsets.popper.top = floor(reference.bottom);
        }
    }

    return data;
}
