import getClientRect from '../utils/getClientRect';

/**
 * Modifier used to shift the popper on the start or end of its reference element side
 * @method
 * @memberof Modifiers
 * @argument {Object} data - The data object generated by `update` method
 * @argument {Object} options - Modifiers configuration and options
 * @returns {Object} The data object, properly modified
 */
export default function shift(data) {
    const placement = data.placement;
    const basePlacement = placement.split('-')[0];
    const shiftvariation = placement.split('-')[1];

    // if shift shiftvariation is specified, run the modifier
    if (shiftvariation) {
        const reference = data.offsets.reference;
        const popper = getClientRect(data.offsets.popper);

        const shiftOffsets = {
            y: {
                start:  { top: reference.top },
                end:    { top: reference.top + reference.height - popper.height }
            },
            x: {
                start:  { left: reference.left },
                end:    { left: reference.left + reference.width - popper.width }
            }
        };

        const axis = ['bottom', 'top'].indexOf(basePlacement) !== -1 ? 'x' : 'y';

        data.offsets.popper = {...popper, ...shiftOffsets[axis][shiftvariation]};
    }

    return data;
}
