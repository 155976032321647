import getClientRect from '../utils/getClientRect';
import getOppositePlacement from '../utils/getOppositePlacement';

/**
 * Modifier used to make the popper flow toward the inner of the reference element.
 * By default, when this modifier is disabled, the popper will be placed outside
 * the reference element.
 * @method
 * @memberof Modifiers
 * @argument {Object} data - The data object generated by `update` method
 * @argument {Object} options - Modifiers configuration and options
 * @returns {Object} The data object, properly modified
 */
export default function inner(data) {
    const placement = data.placement;
    const basePlacement = placement.split('-')[0];
    const popper = getClientRect(data.offsets.popper);
    const reference = getClientRect(data.offsets.reference);
    const isHoriz = ['left', 'right'].indexOf(basePlacement) !== -1;

    const subtractLength = (['top', 'left'].indexOf(basePlacement) === -1);

    popper[isHoriz ? 'left' : 'top'] = reference[placement] - (subtractLength ? popper[isHoriz ? 'width' : 'height'] : 0);

    data.placement = getOppositePlacement(placement);
    data.offsets.popper = getClientRect(popper);

    return data;
}
